import React from "react";
import '../../static/css/home.css';
import '../../static/css/contact.css';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {motion} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';

interface ContactProps {
    additionalClass?: string; // optionales Prop vom Typ string
}

const Contact: React.FC<ContactProps> = ({ additionalClass }) => {
    return (
        <div>
            <div className={`section section-contact ${additionalClass ? additionalClass : ''}`}>
                <Container>
                    <motion.p
                        className={'section-header-title'}
                        initial={{ opacity: 0, y: -20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        Kontakt
                    </motion.p>
                    <Row className="text-center">
                        <Col xs={12}>
                            <motion.p
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                Ich freue mich darauf, Sie und Ihr Kind zu unterstützen!
                            </motion.p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} md={6}  className="contact-card">
                            <motion.div
                                className="card contact-card"
                                initial={{ scale: 0 }}
                                whileInView={{ scale: 1 }}
                                transition={{ duration: 0.5 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                <div className="card-header">
                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                    <h5 className="card-title">E-Mail</h5>
                                </div>
                                <div className="card-body">
                                    <a href="mailto:ks-stillberatung@googlemail.com">ks-stillberatung@googlemail.com</a>
                                </div>
                            </motion.div>
                        </Col>
                        <Col xs={12} md={6} className="contact-card">
                            <motion.div
                                className="card contact-card"
                                initial={{ scale: 0 }}
                                whileInView={{ scale: 1 }}
                                transition={{ duration: 0.5 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                <div className="card-header">
                                    <FontAwesomeIcon icon={faPhone} className="icon" />
                                    <h5 className="card-title">Telefon / Whatsapp</h5>
                                </div>
                                <div className="card-body">
                                    <p>+49 (0)176-74 73 95 76</p>
                                </div>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Contact;
