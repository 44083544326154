import React, {useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

export const CustomFooter = () => {
    const apiToken = process.env.REACT_APP_API_TOKEN;
    const serverUrl = process.env.REACT_APP_API_URL;
    const versionNumber = "1.0.0"; // Hier die Versionsnummer setzen

    const [visitorCount, setVisitorCount] = useState(null); // State für Besucherzahl

    useEffect(() => {
        const hasVisited = sessionStorage.getItem("hasVisited");

        if (!hasVisited) {
            fetch(`${serverUrl}/counter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setVisitorCount(data);
                    sessionStorage.setItem("hasVisited", "true");
                })
                .catch(error => console.error('Error updating counter:', error));
        } else {
            fetch(`${serverUrl}/counter`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setVisitorCount(data);
                })
                .catch(error => console.error('Error updating counter:', error));
        }
    }, [apiToken, serverUrl]);

    return (
        <div className={'footer-wrapper'} style={{ position: 'relative' }}>
            <Navbar className={'navbar'} bg="light">
                <Container>
                    <Nav className="justify-content-around flex-grow-1">
                        <Nav.Link href="/contact">Kontakt</Nav.Link>
                        <Nav.Link href="/privacyPolicy">Datenschutz</Nav.Link>
                        <Nav.Link href="/impressum">Impressum</Nav.Link>
                    </Nav>
                </Container>
                {/* Versionsnummer und Besucherzahl */}
                <div className="version-number" style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    fontSize: '12px',
                    color: 'gray'
                }}>
                    Version: {versionNumber} / {visitorCount !== null ? visitorCount : "Loading..."}
                </div>
            </Navbar>
        </div>
    );
}
