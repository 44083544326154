import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const FloatingButton = ({}) => {
    const navigate = useNavigate(); // Verwende den Hook, um eine Navigate-Funktion zu erhalten

    const handleButtonClick = () => {
        navigate('/contact'); // Navigiert zur Kontaktseite
    };

    return (
        <Button className="floating-button" onClick={handleButtonClick}>
            Termin buchen
        </Button>
    );
};

export default FloatingButton;
