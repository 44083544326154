import React from "react";
import '../../static/css/home.css';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

const PrivacyPolicy = () => {
    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <h1>Datenschutz</h1>
                    <p>
                        Nach der DSGVO bin ich verpflichtet, Sie darüber zu informieren, zu welchem Zweck ich
                        Kundendaten erhebe, speichere oder weiterleite. Personenbezogene Daten sind alle Informationen,
                        mit denen Sie persönlich identifiziert werden können. Nachfolgend unterrichte ich Sie über den
                        Umgang mit Ihren Daten und über Ihre Rechte hinsichtlich des Datenschutzes.
                    </p>
                    <h2>Allgemeine Hinweise und Pflichtinformationen</h2>
                    <p>
                        Die Betreiber dieser Webseite nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
                        behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
                        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    </p>
                    <p>
                        Wenn Sie diese Webseite benutzen, werden verschiedene personenbezogene Daten erhoben.
                        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
                        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
                        Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                    </p>
                    <p>
                        Diese Datenschutzerklärung nimmt ausschließlich Bezug auf diese Webseite. Für weiterführende
                        Links zu Webseiten von Dritten übernehmen wir keine Haftung für deren Erhebung, Verarbeitung und
                        Nutzung Ihrer personenbezogenen Daten. Bitte überprüfen Sie selbst die Datenschutzerklärungen
                        auf den verlinkten Webseiten.
                    </p>
                    <p>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per
                        E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                        Dritte ist nicht möglich.
                    </p>

                    <h2>Hinweis zur verantwortlichen Stelle</h2>
                    <p>
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Webseite ist:<br/>
                        Karin Schösser<br/>
                        Westring 17<br/>
                        94124 Büchlberg<br/>
                        E-Mail: <a href="mailto:ks-stillberatung@gmail.com">ks-stillberatung@gmail.com</a><br/>
                        Telefon: +49 (0)176 – 74 73 95 76<br/>
                        Steuernummer: 153/270/61701
                    </p>

                    <h2>Speicherdauer</h2>
                    <p>
                        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
                        verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung
                        entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
                        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
                        zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder
                        handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
                        Fortfall dieser Gründe.
                    </p>

                    <h2>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
                    <p>
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
                        können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
                        Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                    </p>
                    <h2>
                        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
                    </h2>
                    <p>
                        Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben
                        Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die
                        Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf
                        diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
                        Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen,
                        werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
                        können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                        Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                        Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
                        Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das
                        Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum
                        Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
                        Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten
                        anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
                        DSGVO).
                    </p>
                    <h2>
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    </h2>
                    <p>
                        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                        Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
                        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                    </p>
                    <h2>
                        Recht auf Datenübertragbarkeit
                    </h2>
                    <p>
                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                        Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
                        maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
                        einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                    </p>
                    <h2>
                        Auskunft, Löschung und Berichtigung
                    </h2>
                    <p>
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
                        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
                        Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
                        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                        jederzeit an uns wenden.
                    </p>
                    <h2>
                        Recht auf Einschränkung der Verarbeitung
                    </h2>
                    <p>
                        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                        verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der
                        Verarbeitung besteht in folgenden Fällen:
                        a) Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
                        benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
                        Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        b) Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können
                        Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                        c) Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
                        Ausübung,Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht,
                        statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                        verlangen.
                        d) Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
                        zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
                        Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen.
                        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten
                        – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
                        oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                        juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen
                        Union oder eines Mitgliedstaats verarbeitet werden.
                    </p>
                    <h2>
                        Widerspruch gegen Werbe-E-Mails
                    </h2>
                    <p>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
                        von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                        widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle
                        der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    </p>
                    <h2>
                        Anfrage per E-Mail, Telefon oder Telefax
                    </h2>
                    <p>
                        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller
                        daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres
                        Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
                        Einwilligung weiter.
                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
                        Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                        Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem
                        berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6
                        Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                        abgefragt wurde.
                        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
                        Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
                        Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
                        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

const PrivacyPolicyAlt = () => {
    return (
        <div>
            Datenschutz
            Nach der DSGVO bin ich verpflichtet, Sie darüber zu informieren, zu welchem
            Zweck ich Kundendaten erhebe, speichere oder weiterleite.
            Personenbezogene Daten sind alle Informationen, mit denen Sie persönlich
            identifiziert werden können. Nachfolgend unterrichte ich Sie über den
            Umgang mit Ihren Daten und über Ihre Rechte hinsichtlich des Datenschutzes.
            Allgemeine Hinweise und Pflichtinformationen
            Die Betreiber dieser Webseite nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
            Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            Wenn Sie diese Webseite benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht.
            Diese Datenschutzerklärung nimmt ausschließlich Bezug auf diese Webseite. Für weiterführende Links
            zu Webseiten von Dritten übernehmen wir keine Haftung für deren Erhebung, Verarbeitung und
            Nutzung Ihrer personenbezogenen Daten. Bitte überprüfen Sie selbst die Datenschutzerklärungen auf
            den verlinkten Webseiten.
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
            Hinweis zur verantwortlichen Stelle
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Webseite ist:
            Karin Schösser
            Westring 17
            94124 Büchlberg
            E-Mail: ks-stillberatung@gmail.com
            Telefon: +49 (0)176 – 74 73 95 76
            Steuernummer: 153/270/61701
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-
            Adressen o. Ä.) entscheidet.
            Speicherdauer
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
            verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
            Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
            Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
            zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall
            dieser Gründe.
            Widerruf Ihrer Einwilligung zur Datenverarbeitung

            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können
            eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie
            jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die
            Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese
            Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre
            betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
            überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
            Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das
            Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum
            Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten
            anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
            DSGVO).
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
            unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            Recht auf Datenübertragbarkeit
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
            Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            Auskunft, Löschung und Berichtigung
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
            unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
            Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
            dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit an uns wenden.
            Recht auf Einschränkung der Verarbeitung
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
            a) Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
            benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht,
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            b) Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können
            Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
            c) Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
            Ausübung,Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht,
            statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.

            d) Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
            zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
            Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten –
            von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
            juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
            oder eines Mitgliedstaats verarbeitet werden.
            Widerspruch gegen Werbe-E-Mails
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
            von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen.
            Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
            Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
            Anfrage per E-Mail, Telefon oder Telefax
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres
            Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung
            weiter.
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage
            mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
            der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
            Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
            gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
        </div>
    );
}

export default PrivacyPolicy;