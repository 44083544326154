import React from "react";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {motion} from "framer-motion"; // Importiere Framer Motion
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'; // Importiere Font Awesome
import {faBaby, faBabyCarriage, faBed, faHandsHelping, faUtensils} from '@fortawesome/free-solid-svg-icons'; // Icons importieren
import '../../static/css/modHandling.css';
import hodHandlingImg from "../../../src/static/images/mod-handling.jpg"; // Bild importieren

interface ModHandlingProps {
    additionalClass?: string; // optionales Prop vom Typ string
}

const ModHandling: React.FC<ModHandlingProps> = ({ additionalClass }) => {
    return (
        <div id="modHandling">
            <div className={`section section-modHandling ${additionalClass ? additionalClass : ''}`}>
                <Container>
                    <motion.p
                        className="section-header-title"
                        initial={{ opacity: 0, y: -20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        „Baby-Workshop“ - Kurs
                    </motion.p>

                    <Row className="align-items-center text-center">
                        <Col xs={12} md={6}>
                            <motion.p
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                In meinem Baby-Workshop lernen Sie die wichtigsten Grundlagen kennen, um Ihr Baby sicher und liebevoll zu versorgen.
                            </motion.p>

                            <motion.p
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                In diesem Kurs werden Sie wertvolle Fähigkeiten erlernen, die Ihnen helfen werden, Ihre Rolle als Eltern mit Vertrauen und Freude auszufüllen. Die Themen umfassen unter anderem:
                            </motion.p>

                            <motion.ul
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                <li><FontAwesomeIcon icon={faHandsHelping} /> Sicheres Halten und Tragen</li>
                                <li><FontAwesomeIcon icon={faBaby} /> Windelwechsel und Pflege</li>
                                <li><FontAwesomeIcon icon={faUtensils} /> Stillen bzw. Flaschennahrung</li>
                                <li><FontAwesomeIcon icon={faBed} /> Schlafgewohnheiten</li>
                                <li><FontAwesomeIcon icon={faBabyCarriage} /> Entwicklung und Spiel</li>
                            </motion.ul>

                            <motion.p
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                Ich freue mich darauf, Sie auf dieser spannenden Reise zu begleiten und Ihnen dabei zu helfen, das Beste für Ihr Baby zu geben.
                            </motion.p>

                            <motion.p
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 1.0 }}
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                Lassen Sie uns gemeinsam eine liebevolle und sichere Umgebung schaffen, in der Ihr Baby sicher und geborgen wachsen kann.
                            </motion.p>
                        </Col>

                        <Col xs={12} md={6}>
                            <motion.img
                                src={hodHandlingImg} // Pfad zu deinem Bild hier
                                alt="Baby Workshop"
                                initial={{ opacity: 0, scale: 0.8 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                viewport={{ once: true, amount: 0.2 }}
                                className="img-fluid" // Macht das Bild responsiv
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default ModHandling;
