import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {motion} from 'framer-motion';
import '../../../static/css/timeline.css';
import TimelineItem from './timelineItem';

const Timeline = () => {
    const timelineData = [
        {
            icon: 'fas fa-lightbulb',
            date: '2014',
            title: 'Examen',
            description: 'Gesundheits- und Kinderkrankenpflegerin Kinderkrankenhaus Landshut',
        },
        {
            icon: 'fas fa-lightbulb',
            date: '2014',
            title: 'Erfahrung',
            description: 'Neonatologische Intensivstation LMU Klinikum Großhadern',
        },
        {
            icon: 'fas fa-lightbulb',
            date: '2016',
            title: 'Erfahrung',
            description: 'Herzkatheterlabor für Angeborene Herzfehler Deutsches Herzzentrum München der TUM',
        },
        {
            icon: 'fas fa-lightbulb',
            date: '2019',
            title: 'Weiterbildung',
            description: 'Still- und Laktationsberaterin IBCLC',
        },
        {
            icon: 'fas fa-lightbulb',
            date: '2020',
            title: 'Examen',
            description: 'International Board of Lactation Consultant Examiniers',
        },
    ];

    return (
        <div className="section section-timeline">
            <Container className="margin-bottom">
                <p className="section-header-title">Meine Qualifikationen</p>
                <Row>
                    {timelineData.map((item, index) => (
                        <Col xs={12} md={4} className="margin-top" key={index}>
                            <motion.div
                                className="timeline-container"
                                initial={{ opacity: 0, y: 50 }} // Elemente starten 50px tiefer und sind unsichtbar
                                whileInView={{ opacity: 1, y: 0 }} // Sie bewegen sich in die richtige Position
                                transition={{
                                    duration: 0.8,
                                    ease: 'easeOut',
                                    delay: index * 0.3, // Verzögerung pro Item basierend auf dem Index (jedes 0.3s später)
                                }}
                                viewport={{ once: true, amount: 0.2 }} // Animation wird einmal bei 20% Sichtbarkeit getriggert
                            >
                                <TimelineItem
                                    icon={item.icon}
                                    date={item.date}
                                    title={item.title}
                                    description={item.description}
                                />
                            </motion.div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default Timeline;
