import React from "react";
import '../../static/css/home.css';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";


const Impressum = () => {
    return (
        <div>
            <div className={"section"}>
                <Container>
                    <p className={'section-header-title'}>Impressum</p>
                    <Row className="text-center">
                        <Col xs={12} className="">
                            <p>
                                Karin Schösser
                            </p>
                            <p>
                                Westring 17
                            </p>
                            <p>
                                94124 Büchlberg
                            </p>
                            <p>
                                E-Mail:
                            </p>
                            <p>
                                <a href="mailto:ks-stillberatung@googlemail.com">ks-stillberatung@googlemail.com</a>
                            </p>
                            <p>
                                Telefon:
                            </p>
                            <p>
                                +49 (0)176-74 73 95 76
                            </p>
                            <p>
                                UstNr.: 153/270/61701
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Impressum;