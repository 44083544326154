import './static/css/overall.css';
import './static/css/colors.css';
import './static/css/button.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React from 'react';
import Home from "./components/Home";
import {CustomFooter} from "./components/CustomFooter";
import Contact from "./components/contact/contact";
import PrivacyPolicy from "./components/privacyPolicy/privacyPolicy";
import Impressum from "./components/impressum/impressum";
import BreastFeeding from "./components/breastFeeding/breastFeeding";
import AboutMe from "./components/aboutMe/aboutMe";
import ModHandling from "./components/modHandling/modHandling";
import Workshop from "./components/breastFeedingWorkshop/breastFeedingWorkshop";
import Babyfood from "./components/babyFood/babyfood";
import FloatingButton from "./components/utilities/FloatingButton";
import CustomNavBar from "./components/CustomNavBar";

export default class App extends React.Component {
    render() {
        return (
            <div className="App" style={{minHeight: '100vh'}}>
                <Router>
                    <CustomNavBar/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/contact" element={<Contact additionalClass="page-standalone"/>}/>
                            <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                            <Route path="/impressum" element={<Impressum/>}/>
                            <Route path="/breastFeeding" element={<BreastFeeding additionalClass="page-standalone" />} />
                            <Route path="/modhandling" element={<ModHandling additionalClass="page-standalone"/>}/>
                            <Route path="/breastFeedingWorkshop" element={<Workshop additionalClass="page-standalone"/>}/>
                            <Route path="/babyfood" element={<Babyfood additionalClass="page-standalone"/>}/>
                            <Route path="/aboutMe" element={<AboutMe additionalClass="page-standalone"/>}/>
                        </Routes>
                    <FloatingButton/>
                    <CustomFooter/>
                </Router>
            </div>
        );
    }
}
