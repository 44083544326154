import React from "react";
import '../../static/css/breastFeedingWorkshop.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'; // Import FontAwesome
import {faBaby, faCheckCircle, faLeaf, faUtensils} from '@fortawesome/free-solid-svg-icons'; // Import specific icons
import breastfeedingImage from '../../static/images/breastfeeding-course.jpg'; // Import your image
import {motion} from 'framer-motion'; // Import Framer Motion

interface BreastFeedingWorkshopProps {
    additionalClass?: string; // optional prop of type string
}

const BreastFeedingWorkshop: React.FC<BreastFeedingWorkshopProps> = ({ additionalClass }) => {
    return (
        <div id={'breastFeedingWorkshop'}>
            <div className={`section section-breastFeedingWorkshop ${additionalClass ? additionalClass : ''}`}>
                <Container>
                    <p className={'section-header-secondary-title'}>Stillvorbereitungsseminar</p>
                    <div className={'text-center'}>
                    <p>Als erfahrene Stillberaterin ist es mir ein besonderes Anliegen, Müttern und Familien
                        bestmöglich auf die wertvolle und intime Zeit des Stillens vorzubereiten.
                    </p>
                    <p>
                        Stillen ist eine natürliche aber auch erlernbare Fähigkeit.
                    </p>
                    <p>
                        Mein Stillvorbereitungsseminar richtet sich an werdende Mütter und ihre Partner, die
                        sich optimal auf das Stillen vorbereiten möchten.
                    </p>
                    <p>
                        In einer entspannten Atmosphäre vermittle ich Ihnen fundiertes Wissen über:
                    </p>
                    </div>
                    <Row className={'align-item-center'}>
                        <Col xs={12} className="d-flex justify-content-center">
                            <motion.img
                                src={breastfeedingImage}
                                alt="Breastfeeding"
                                className="breastfeeding-image"
                                initial={{ opacity: 0, scale: 0.8 }} // Initial state
                                whileInView={{ opacity: 1, scale: 1 }} // Animation state
                                transition={{ duration: 0.5 }} // Transition duration
                                viewport={{ once: true, amount: 0.2 }}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center"> {/* Center the items vertically */}
                        <Col xs={12} className="d-flex justify-content-center">
                            <motion.ul
                                className="breastfeeding-list"
                                initial={{ opacity: 0, y: 20 }} // Initial state
                                whileInView={{ opacity: 1, y: 0 }} // Animation state
                                transition={{ duration: 0.5 }} // Transition duration
                                viewport={{ once: true, amount: 0.2 }}
                            >
                                <li><FontAwesomeIcon icon={faCheckCircle} /> Die Bedeutung und Vorteile des Stillens für Mutter und Kind</li>
                                <li><FontAwesomeIcon icon={faBaby} /> Die ersten Schritte nach der Geburt: Anlegen und Bonding</li>
                                <li><FontAwesomeIcon icon={faLeaf} /> Verschiedene Stillpositionen</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} /> Häufige Herausforderungen und wie man sie meistert</li>
                                <li><FontAwesomeIcon icon={faUtensils} /> Ernährung der stillenden Mutter</li>
                                <li><FontAwesomeIcon icon={faCheckCircle} /> Praktische Tipps zur Vorbereitung auf das Stillen und zur Stillorganisation im Alltag</li>
                            </motion.ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default BreastFeedingWorkshop;
