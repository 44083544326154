import React from "react";
import '../../static/css/babyFood.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {motion} from 'framer-motion'; // Import Framer Motion

interface BabyfoodProps {
    additionalClass?: string; // optionales Prop vom Typ string
}

const Babyfood: React.FC<BabyfoodProps> = ({additionalClass}) => {
    return (
        <div id={'babyFood'}>
            <div className={`section section-babyFood ${additionalClass ? additionalClass : ''}`}>
                <motion.div
                    initial={{opacity: 0, y: -20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.5, delay: 0.5}}
                    viewport={{once: true, amount: 0.2}}
                >
                    <Container className={'container-babyFood'}>
                        <p className={'section-header-title'}>Beikostworkshop</p>
                        <Row className="">

                            <Col xs={12} className="text-center">
                                <p>
                                    Die Einführung der Beikost ist ein bedeutender Schritt in der Entwicklung Ihres
                                    Babys
                                    und sollte daher so stressfrei wie möglich gestaltet werden.
                                </p>
                                <p>
                                    Mein Kurs bietet Ihnen umfassende Informationen und praktische Unterstützung, um
                                    diesen Übergang erfolgreich zu gestalten.
                                </p>
                                <p>
                                    Ich gebe Ihnen praktische Tipps zur Zubereitung und zur Vermeidung von Allergien
                                    und Unverträglichkeiten.
                                </p>
                                <p>
                                    Der Kurs richtet sich an Eltern, Großeltern und Betreuungspersonen, die sich
                                    umfassend informieren möchten.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </div>
        </div>
    )
        ;
}

export default Babyfood;