import React from 'react';
import {Col, Row} from "react-bootstrap";

interface TimelineItemProps {
    icon: string;
    date: string;
    title: string;
    description: string;
}

const TimelineItem: React.FC<TimelineItemProps> = ({icon, date, title, description}) => {
    return (

            <div className="timeline-item">
                <Row>
                    <Col xs={'auto'}>
                        <div className="timeline-icon">
                            <i className={icon}></i>
                        </div>
                    </Col>
                </Row>
                <div className="timeline-content margin-top">
                    <h3>{date} {title}</h3>
                    <p>{description}</p>
                </div>
            </div>
    );
}

export default TimelineItem;
