import React from "react";
import '../../static/css/home.css';
import '../../static/css/breastFeeding.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {motion} from 'framer-motion'; // Import Framer Motion

interface BreastFeedingProps {
    additionalClass?: string; // optionales Prop vom Typ string
}

const BreastFeeding: React.FC<BreastFeedingProps> = ({ additionalClass }) => {
    return (
        <div id={'breastFeeding'}>
            <div className={`section section-breastFeeding ${additionalClass ? additionalClass : ''}`}>
                <motion.div
                    initial={{opacity: 0, y: -20}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.5, delay: 0.5}}
                    viewport={{once: true, amount: 0.2}}
                >
                    <Container className={'container-breastFeeding'}>
                        <div>
                            <p className={'section-header-secondary-title'}>Stillberatung</p>
                            <Row>
                                <Col xs={12}>
                                    <p>
                                        Meine Stillberatung bei Ihnen zuhause bietet Ihnen die Möglichkeit in Ihrer
                                        gewohnten Umgebung individuelle Unterstützung und wertvolle Tipps rund um
                                        das Thema Stillen zu erhalten.
                                    </p>
                                    <p>
                                        Ob es um die richtige Anlegetechnik, die Milchbildung oder allgemeine
                                        Unsicherheiten
                                        geht
                                    </p>
                                    <p>
                                        Ich komme zu Ihnen und biete Ihnen meine fachkundige Beratung und umfassende
                                        Unterstützung an.
                                    </p>

                                    <p>Einfühlsam und professionell ermögliche ich Ihnen und Ihrem Baby eine entspannte
                                        und erfolgreiche Stillzeit.
                                    </p>
                                    <p>
                                        Lassen Sie uns gemeinsam die besten Voraussetzungen für das Wohlbefinden Ihrer
                                        Familie schaffen.
                                    </p>
                                    <p>
                                        Die zweiwöchige Nachbetreuung erfolgt professionell per Chat, Email oder über
                                        Telefonate (maximal 10 Minuten).
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </motion.div>
            </div>
        </div>
);
}

export default BreastFeeding;
